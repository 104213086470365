import request from '../request.js';
export function getList(data) {
  return request({
    url: "module-account/account/queryPage",
    method: 'post',
    data
  })
}
export default {
  getList
}